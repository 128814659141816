var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nuxt-link',{staticClass:"\n    inline-flex\n    items-center\n    justify-between\n    px-3\n    py-2\n    border-2\n    rounded-md\n    transition-colors\n    duration-300\n    ease-in-out\n  ",class:{
    'border-charcoal-black bg-charcoal-black text-white hover:bg-transparent hover:text-charcoal-black':
      _vm.type === 'dark-full-dark-outline',
    'bg-charcoal-black border-charcoal-black text-white hover:bg-transparent hover:text-dusty-pink hover:border-dusty-pink':
      _vm.type === 'dark-full-red-outline',
    'border-dusty-pink text-dusty-pink hover:bg-dusty-pink hover:text-white':
      _vm.type === 'red-outline-red-full',
  },attrs:{"to":_vm.url}},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.text))]),_vm._v(" "),_c('AtomsIconMore')],1)}
var staticRenderFns = []

export { render, staticRenderFns }