import { render, staticRenderFns } from "./HomeSectionEmployeeBranding.vue?vue&type=template&id=2535ea98&"
import script from "./HomeSectionEmployeeBranding.vue?vue&type=script&lang=js&"
export * from "./HomeSectionEmployeeBranding.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesButtonMore: require('/home/studioars/applications/new-studioars.pl/components/molecules/ButtonMore.vue').default})
